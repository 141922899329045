import React, {useEffect, useState} from "react";
import lodash from "lodash";
import NumberPickerTop from "./numberPicker/NumberPickerTop";
import { types, useAlert } from "react-alert";
import { checkMarks, checkTotalPercent, checkPercents } from '../../utils/checks';
import { BadgeCheckIcon, BookOpenIcon } from "@heroicons/react/solid";

const CalculatorTop = (props) => {
    const {
        finalMarkTop,
        setFinalMarkTop,
        wantedMark,
        setWantedMark,
        evaluators,
        setEvaluators,
        subEvaluators,
        currentMark,
        setCurrentMark,
        isTotalGrade,
        setIsTotalGrade,
        setIsApproved,
        setNotPossibleApproval
    } = props;

    let eMark = 0
    let id;
    const alert = useAlert();
    const [noInputs, setNoInputs] = useState(true);
    
    useEffect(() => {
       evaluators.map((e) => {
           if(e.mark !== 0) {
               setNoInputs(false);
           }
       })
    }, [evaluators])

    useEffect(() => {
        setEvaluators(
        evaluators.map((e) => {
            if (e.isActive) {
            subEvaluators.forEach((sub) => {
                if (sub.isActive) {
                if(sub.parentId === e.id) {
                    eMark += (sub.mark * sub.percent) / 100;
                    id = sub?.parentId;
                } else {
                    return;
                }
                }
            })          
            }  
            if (eMark !== 0 && eMark !== undefined && e.id === id) {
            return {
                ...e,
                mark: eMark.toFixed(2),
                };
            } else {
            return e;
            }
        })
        );
    }, [subEvaluators])
    
    useEffect(() => {
        if(checkPercents(evaluators) && checkMarks(evaluators) && checkTotalPercent(evaluators)) {
            let marks = lodash.sum(evaluators.map((e) => (e.percent / 100) * e.mark));
            setCurrentMark(marks.toFixed(2));
            
            let finalPercent = 1 - lodash.sum(evaluators.map((e) => e.percent / 100));
            setIsTotalGrade(finalPercent === 0 ? true : false);
            let mark = ((wantedMark - marks) / finalPercent).toFixed(2);
            setIsApproved(mark <= 0 ? true : false);
            setNotPossibleApproval(mark >= 10 ? true : false);

            if (isTotalGrade) {
                setFinalMarkTop(marks.toFixed(2));
                setCurrentMark("Tu nota es 👆")
            } else {
                if (mark >= 10) {
                    setFinalMarkTop(<BookOpenIcon className="h-16 w-16" />);
                    return;
                } else if (mark < 0) {
                    setFinalMarkTop(<BadgeCheckIcon className="h-16 w-16" />);
                    return;
                } else {
                    setFinalMarkTop(mark);
                    setCurrentMark(marks.toFixed(2));
                    return;
                }
            }
        } else {
            setFinalMarkTop("Epa");
            setCurrentMark("!!");
            setNoInputs(false);
            return;
        }
    }, [evaluators, wantedMark])

    useEffect(() => {
        if (finalMarkTop === "Cuidao"){
            alert.error(`Revisa que los números esten bien escritos`, {
                type: types.ERROR,
            });
        } 
        if (finalMarkTop === "Epa") {
            alert.error(`Comprueba que las notas y los porcentajes sean correctos`, {
                type: types.ERROR,
            });
        }
    }, [finalMarkTop])

    return (
        <div className="h-full w-full flex flex-wrap justify-center content-center text-xl text-white">
            <div className="flex flex-col justify-center content-center text-center py-3">
                {currentMark !== "0.00" && <h1 className="w-100 text-5xl pt-5 font-bold">{finalMarkTop}</h1>}
                {currentMark !== "0.00" && <h5 className="m-0 text-2xl font-bold text-gray-200">{currentMark}</h5>}
                {noInputs && <h5 className="m-0 px-12 text-xl font-semithin font-courgette text-sky-900">Selecciona la nota que desearias obtener en el final</h5>}
            </div>
            <NumberPickerTop wantedMark={wantedMark} setWantedMark={setWantedMark} />
        </div>
    );
};

export default CalculatorTop;