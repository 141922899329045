export const checkMarks = (evaluators) => {
  let result = true;
  evaluators.forEach((e) => {
    if(e.mark !== "") {
      let parsedMark = parseInt(e.mark);
      
      if (isNaN(parsedMark) || parsedMark > 10 || parsedMark < 0) {
          result = false;
          return;
      }
    } 
  })
  return result;
}

export const checkPercents = (evaluators) => {
  let result = true;
  evaluators.forEach((e) => {
    if(e.percent !== "") {
      let parsedPercent= parseInt(e.percent);
      if (isNaN(parsedPercent) || parsedPercent > 100 || parsedPercent < 0) {
          result = false;
          return;
      }
    } 
  })
  return result;
}

export const checkTotalPercent = (evaluators) => {
  let result = true;
  let totalPercent = 0;
  evaluators.forEach((e) => {
    if(e.percent !== "") {
      if(isNaN(parseInt(e.percent))) {
        result = false;
        return;
      }
      totalPercent += parseInt(e.percent);
    }
  })
  if (totalPercent > 100) {
    result = false;
    return;
  }
  return result;
}
