import React from "react";
import SaveForm from "./SaveForm";

function SaveBtn(props) {
  const {
    evaluators,
    subEvaluators,
    setModalContent,
    setModalIsOpen,
    subjects,
    setSubjects,
    newSubject,
    setNewSubject
  } = props;

  const handleSaveClick = () => {
    setModalIsOpen(true);
    setModalContent(
      <SaveForm 
        evaluators={evaluators} 
        subEvaluators={subEvaluators} 
        setModalIsOpen={setModalIsOpen} 
        subjects={subjects} 
        setSubjects={setSubjects}                 
        newSubject={newSubject}
        setNewSubject={setNewSubject}
        />
    );
  };

  return (
    <button
    className="w-full py-1 px-5 text-right text-green-700
      lg:px-3 lg:py-2 lg:mx-2 lg:rounded-lg lg:text-gray-500 lg:text-center lg:border lg:border-transparent lg:hover:text-gray-100 lg:hover:bg-green-700 lg:hover:border-green-700 transition-all
      border-transparent text-md  self-end bg-transparent 
      hover:cursor-pointer"
      onClick={handleSaveClick}
    >
      Guardar
    </button>
  );
}

export default SaveBtn;
