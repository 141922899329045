import React from 'react';
import { TrashIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import { types, useAlert } from "react-alert";

const SubjectsTable = (props) => {

    const {
        subjects,
        setSubjects,
        setEvaluators,
        setSubEvaluators,
        setModalIsOpen
    } = props;
    const alert = useAlert();

    const handleApply = (sub) => {
        subjects.forEach((s) => {
            if (s.id === sub.id && sub?.name !== undefined) {
                if (s.evaluators !== []) {
                    setEvaluators(s.evaluators);
                }
                if (s.subEvaluators !== []) {
                    setSubEvaluators(s.subEvaluators);
                }
            }
        });
        alert.success(`L'assignatura: ${sub?.name} s'ha recuperat correctament`, {
            type: types.SUCCESS,
        });
        setModalIsOpen(false);
    };

    const handleRemove = (sub) => {
        let filteredSubjects = subjects.filter((s) => s.id !== sub.id);
        setSubjects(filteredSubjects);
        try {
           localStorage.setItem("subjects", JSON.stringify(subjects));  
        } catch (e) {
            console.error(e);
        }
        alert.success(`L'assignatura: ${sub?.name} s'ha eliminat correctament`, {
            type: types.SUCCESS,
        });
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Nombre
                                    </th>
                                    <th
                                        scope="col"
                                        className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Criterios
                                    </th>
                                    <th scope="col" className="relative pr-6 lg:px-4 py-3 text-right lg:text-center text-xs font-medium text-gray-500 uppercase">
                                        Opciones
                                    </th>
                                </tr>
                            </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {subjects?.map((subject) => (
                            <tr key={subject?.name}>
                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                    <div className="text-sm text-gray-900">{subject?.name}</div>
                                </td>
                                <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-700">{
                                        subject?.evaluators.map((evaluator) => {
                                            return `${evaluator?.name} [${evaluator?.mark}] `
                                        })
                                    }</div>
                                </td>
                                <td className="px-4 py-4 flex justify-end lg:justify-center whitespace-nowrap text-sm font-medium">
                                    <button onClick={() => handleApply(subject)} className="h-8 w-8 mr-3 text-gray-100 bg-green-700 rounded-lg px-2 py-1 hover:bg-green-600 transition-all">
                                        <ClipboardCopyIcon className="h-4 w-4 m-auto" aria-hidden="true"/>
                                    </button>
                                    <button onClick={() => handleRemove(subject)} className="h-8 w-8 text-gray-100 bg-red-700 rounded-lg hover:bg-red-600 transition-all">
                                        <TrashIcon className="h-4 w-4 m-auto" aria-hidden="true" />
                                    </button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubjectsTable;
