import React, { useState } from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { XIcon, MenuIcon } from '@heroicons/react/outline'
import InfoBtn from '../infoBtn/InfoBtn';
import ResetBtn from '../resetBtn/ResetBtn';
import SaveBtn from '../saveBtn/SaveBtn';
import SubjectsBtn from '../subjectsBtn/SubjectsBtn';


const DropDownMenu = (props) => {
    const {
        subjects,
        setSubjects,
        setModalIsOpen,
        setModalContent,
        evaluators,
        setEvaluators,
        subEvaluators,
        setSubEvaluators,
        setFinalMark,
        setWantedMark,
        newSubject,
        setNewSubject
    } = props;

    const [menuOpen, setMenuOpem] = useState(false);
    
    const handleClick = () => {
        setMenuOpem(!menuOpen)
    }

    return (
        <Menu as="div" className="block lg:hidden">
            {({ open }) => (
                <>
                <div>
                    <Menu.Button 
                        className="bg-transparent flex text-sm rounded-full"
                        onClick={handleClick}
                    >
                        <span className="sr-only">Open menu</span>
                        {open && <XIcon className="block h-6 w-6" aria-hidden="true" />}
                        {!open && <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right mr-5 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            <InfoBtn
                                setModalIsOpen={setModalIsOpen}
                                setModalContent={setModalContent}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <ResetBtn
                                setEvaluators={setEvaluators}
                                setSubevaluators={setSubEvaluators}
                                setFinalMark={setFinalMark}
                                setWantedMark={setWantedMark}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <SubjectsBtn
                                subjects={subjects}
                                setSubjects={setSubjects}
                                setModalIsOpen={setModalIsOpen}
                                setModalContent={setModalContent}
                                setEvaluators={setEvaluators}
                                setSubEvaluators={setSubEvaluators}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <SaveBtn
                                evaluators={evaluators}
                                subEvaluators={subEvaluators}
                                setModalIsOpen={setModalIsOpen}
                                setModalContent={setModalContent}
                                subjects={subjects}
                                setSubjects={setSubjects}
                                newSubject={newSubject}
                                setNewSubject={setNewSubject}
                            />
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </>
            )}
        </Menu>
    )
};

export default DropDownMenu;
