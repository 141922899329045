import React from "react";
import { useEffect, useState } from "react";

function PercentageInput(props) {
  const { e, evaluators, setEvaluators } = props;
  const [classNames, setClassNames] = useState([]);

  useEffect(() => {
    if (e.isSub) {
      setClassNames([
        "flex justify-center content-center h-full w-full px-2 md:px-4 bg-cyan-600/90 text-white focus-within:border-2 focus-within:border-cyan-600 transition-all",
        "my-auto w-3/6 caret-gray-100 leading-3 text-sm md:text-lg lg:text-xl text-center outline-none border-0 bg-transparent",
        "my-auto leading-3 text-sm md:text-lg lg:text-xl",
      ]);
    } else {
      setClassNames([
        "flex justify-center content-center h-full w-full px-2 md:px-4 bg-cyan-700/90 text-white focus-within:border-2 focus-within:border-cyan-700 transition-all",
        "my-auto w-3/6 caret-gray-100 leading-3 text-md md:text-xl lg:text-3xl text-center outline-none border-0 bg-transparent",
        "my-auto leading-3 text-md md:text-xl lg:text-2xl",
      ]);
    }
  }, [e.id, e.mark, e.isSub, evaluators]);

  const handleChange = (event) => {
    let id = e.id;
    setEvaluators(
      evaluators.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            percent: event.target.value.replace(/,/, '.'),
          };
        } else {
          return e;
        }
      })
    );
  };

  return (
    <div className={classNames[0]}>
      <input
        className={classNames[1]}
        type="text"
        onChange={handleChange}
        value={e.percent || ""}
      />
      <p className={classNames[2]}>%</p>
    </div>
  );
}

export default PercentageInput;
