import React from "react";
import { useEffect, useState } from "react";

function MarkInput(props) {
  const { e, evaluators, setEvaluators } = props;
  const [classNames, setClassNames] = useState([]);

  useEffect(() => {
    let mark = e.mark;
    let id = "mark" + e.id;
    if (e.mark !== 0 && !e.isSub) {
      evaluators.forEach(() => {
        document.getElementById(id).value = mark.toString();
      });
    }

    if (e.isSub) {
      setClassNames([
        "flex justify-center content-center h-full w-full px-2 md:px-4 bg-emerald-600/90 text-white focus-within:border-2 focus-within:border-emerald-600 transition-all",
        "my-auto w-3/6 caret-gray-100 leading-3 text-sm md:text-lg lg:text-xl text-center outline-none border-0 bg-transparent appearance-none",
        "my-auto leading-3 text-sm md:text-lg lg:text-xl",
      ]);
    } else {
      setClassNames([
        "flex justify-center content-center h-full w-full px-2 md:px-4 bg-green-700/80 text-white focus-within:border focus-within:border-green-700 transition-all",
        "appearance-none my-auto w-3/6 caret-gray-100 leading-3 text-md md:text-xl lg:text-3xl text-center outline-none border-0 bg-transparent ",
        "my-auto leading-3 text-md md:text-xl lg:text-2xl",
      ]);
    }
  }, [e.id, e.mark, e.isSub, evaluators]);

  const handleChange = (event) => {
    let id = e.id;
    setEvaluators(
      evaluators.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            mark: event.target.value.replace(/,/, '.'),
          };
        } else {
          return e;
        }
      })
    );
  };

  return (
    <div className={classNames[0]}>
      <input
        id={`mark${e.id}`}
        className={classNames[1]}
        onChange={handleChange}
        type="text"
        value={(e.mark !== 0 && e.mark !== "0,00") ? e.mark.substring(0, 4) : ""}
      />
      <p className={classNames[2]}>/ 10</p>
    </div>
  );
}

export default MarkInput;
