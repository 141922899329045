//import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
//import instruction_img_1 from "../../../images/instructions/1.png";
import React from "react";
import Instructions from "../../instructions/Instructions";

function InfoBtn(props) {
  const { setModalIsOpen, setModalContent } = props;

  /*const handleClick = () => {
    setModalIsOpen(true);
    setModalContent(
      <div className="flex justify-center w-full h-full">
        <div className="flex content-center justify-center my-auto">
          <button className="text-4xl text-sky-800 border-0 bg-transparent cursor-pointer">
            <ChevronLeftIcon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="w-5/6 h-5/6">
          <img className="min-h-full min-w-full block"src={instruction_img_1} />
        </div>
        <div className="flex content-center justify-center my-auto">
          <button className="text-4xl text-sky-800 border-0 bg-transparent cursor-pointer">
            <ChevronRightIcon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  };*/

  const handleClick = () => {
    setModalIsOpen(true);
    setModalContent(
      <Instructions />
    );
  };

  return (
    <button
      className="w-full py-1 px-5 text-right 
        lg:p-2 lg:mx-2 lg:rounded-lg lg:text-center lg:border lg:border-transparent lg:hover:text-sky-800 lg:hover:border-sky-800 transition-all
        border-transparent text-md text-gray-500 self-end bg-transparent 
        hover:cursor-pointer"
      onClick={handleClick}
    >
      Como funciona
    </button>
  );
}

export default InfoBtn;
