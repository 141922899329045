import React from "react";
import { types, useAlert } from "react-alert";

function ResetBtn(props) {
  const alert = useAlert();
  const { setEvaluators, setSubevaluators, setFinalMark, setWantedMark } = props;

  const handleClick = () => {
    setEvaluators([
      {
        id: 1,
        name: "Parcial 1",
        percent: 0,
        mark: 0,
        isActive: false,
        isSub: false,
        addSub: false,
      },
      {
        id: 2,
        name: "Parcial 2",
        percent: 0,
        mark: 0,
        isActive: false,
        isSub: false,
        addSub: false,
      },
      {
        id: 3,
        name: "Parcial 3",
        percent: 0,
        mark: 0,
        isActive: false,
        isSub: false,
        addSub: false,
      },
      {
        id: 4,
        name: "Laboratorio",
        percent: 0,
        mark: 0,
        isActive: false,
        isSub: false,
        addSub: false,
      },
      {
        id: 5,
        name: "Proyecto",
        percent: 0,
        mark: 0,
        isActive: false,
        isSub: false,
        addSub: false,
      },
    ]);
    setSubevaluators([]);
    setFinalMark("");
    setWantedMark(5);
    alert.show("ElNotas ha sido reiniciado", {
      type: types.INFO,
    });
  };

  return (
    <button
      className="w-full py-1 px-5 text-right 
        lg:p-2 lg:mx-3 lg:rounded-lg lg:text-center lg:border lg:border-transparent lg:hover:text-sky-800 lg:hover:border-sky-800 transition-all
        border-transparent text-md text-gray-500 self-end bg-transparent 
        hover:cursor-pointer"
      onClick={handleClick}
    >
      Resetar
    </button>
  );
}

export default ResetBtn;
