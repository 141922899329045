import React from "react";

function NumberPicker(props) {
  const { wantedMark, setWantedMark } = props;
  const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleClick = (e) => {
    if(e.target.value === undefined) return;
    setWantedMark(parseInt(e.target.value));
  };

  return (
    <div className="h-full justify-self-end flex flex-col justify-center content-center" onClick={handleClick}>
      {nums.map((num) => {
        if (num === wantedMark) {
          return (
            <button
              value={num}
              key={num}
              className="border-none cursor-pointer text-gray-100 bg-gray-600/30 rounded-md font-semibold text-3xl p-2 my-0 transition-all"
              onClick={handleClick}
            >
              {num}
            </button>
          );
        } else {
          return (
            <button
              value={num}
              key={num}
              className="bg-transparent border-none cursor-pointer text-gray-400/60 text-2xl my-0 p-2 hover:text-gray-500 transition-all"
              onClick={handleClick}
            >
              {num}
            </button>
          );
        }
      })}
    </div>
  );
}

export default NumberPicker;
