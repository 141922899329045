import React, {useRef} from "react";
import { PlusSmIcon } from "@heroicons/react/outline";

function EvaluatorAdder(props) {
  const { evaluators, setEvaluators, newEvaluator, setNewEvaluator } = props;
  const inputEv = useRef();

  const handleClick = () => {
    let newId = evaluators.length;
    if(inputEv.current.value !== "") {
      newId += 1;
      setNewEvaluator({
        ...newEvaluator,
        id: newId,
      });
    } 
    if (
      newEvaluator.name !== "" &&
      inputEv.current.value !== ""
    ) {
      setEvaluators([...evaluators, newEvaluator]);
    }
    inputEv.current.value = "";
  };
  
  const handleChange = (event) => {
    setNewEvaluator({
      ...newEvaluator,
      name: event.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="flex content-center h-10 md:h-14 md:min-h-content mt-4 md:mt-6 px-2">
      <input id="evInput" ref={inputEv} className="h-full md:py-6 bg-transparent text-sky-800 text-sm md:text-lg lg:text-2xl border-0 bg-gray-200 rounded-l-lg rounded-r-none px-3 cursor-pointer outline-none" 
        onChange={handleChange} 
        onKeyPress={handleKeyPress}
        placeholder="Añade un criterio"
        />
      <button className="w-8 md:w-12 h-full bg-sky-800 border-0 rounded-r-lg cursor-pointer text-gray-100"  onClick={handleClick} >
        <PlusSmIcon className="w-5 h-5 md:h-6 md:w-6 m-auto" />
      </button>
    </div>
  );
}

export default EvaluatorAdder;
