import React from "react";
import Evaluator from "../evaluator/Evaluator";
import SubAdder from "../evaluator/subAdder/SubAdder";

function EvaluatorGroup(props) {
  const { e, evaluators, setEvaluators, subs, setSubs, newSub, setNewSub, finalMark, setFinalMark} =
    props;

  return (
    <div className="flex flex-col justify-between">
        <Evaluator
            e={e}
            evaluators={evaluators}
            setEvaluators={setEvaluators}
            finalMark={finalMark}
            setFinalMark={setFinalMark}
        />
      {subs.map((sub) => {
        if (sub.parentId === e.id) {
          return (
            <Evaluator 
              key={`parent${e.id}-sub${sub.id}`} 
              e={sub} 
              evaluators={subs} 
              setEvaluators={setSubs} 
            />
          );
        } else {
          return <div />;
        }
      })}
      {e.addSub && (
        <SubAdder
          subs={subs}
          setSubs={setSubs}
          newSub={newSub}
          setNewSub={setNewSub}
          e={e}
        />
      )}
    </div>
  );
}

export default EvaluatorGroup;
