import React, {useEffect} from "react";
import SubjectsTable from "../../subject/SubjectsTable";

function SubjectsBtn(props) {
  const {
    subjects,
    setSubjects,
    setModalContent,
    setModalIsOpen,
    setEvaluators,
    setSubEvaluators,
  } = props;

  useEffect(() => {
    setModalContent(
        <div className="flex flex-col justify-center w-full h-full">
          <h3 className="text-md mb-5 md:text-2xl font-courgette text-sky-900/90 font-bold text-center w-4/6 mx-auto">Asignaturas</h3>
          {subjects?.length === 0 ? 
              <h5 className="text-center text-gray-600 py-8">No tienes ninguna asignatura guardada</h5>
          : <SubjectsTable
              subjects={subjects}
              setSubjects={setSubjects}
              setEvaluators={setEvaluators}
              setSubEvaluators={setSubEvaluators}
              setModalIsOpen={setModalIsOpen}
            />
          }
        </div>
    );
  }, [subjects])

  const handleClick = () => {
    setModalIsOpen(true);
    setModalContent(
      <div className="flex flex-col justify-center w-full h-full">
        <h2 className="text-md mb-5 md:text-2xl font-courgette text-sky-900/90 font-bold text-center w-4/6 mx-auto">Asignaturas</h2>
        {subjects?.length === 0 ? 
              <p className="text-center text-gray-600 py-8">No tienes nignuna asignatura guardada</p>
          : <SubjectsTable
              subjects={subjects}
              setSubjects={setSubjects}
              setEvaluators={setEvaluators}
              setSubEvaluators={setSubEvaluators}
              setModalIsOpen={setModalIsOpen}
            />
          }
      </div>
    );
  };

  return (
    <button
      className="w-full py-1 px-5 text-right 
        lg:p-2 lg:mx-3 lg:rounded-lg lg:text-center lg:border lg:border-transparent lg:hover:text-sky-800 lg:hover:border-sky-800 transition-all
        border-transparent text-md text-gray-500 self-end bg-transparent 
        hover:cursor-pointer"
      onClick={handleClick}
    >
      Asignaturas
    </button>
  );
}

export default SubjectsBtn;
