import React from "react";

function Instructions() {
  const instructions = [
    {
      id: 1,
      text: "Dile a elNotas con que nota deseas aprobar la asignatura",
    },
    {
      id: 2,
      text: "Define los criterios evaluativos",
    },
    /*{
      id: 2,
      text: "També tens la opció de definir criteris evaluatius d'un rang menor",
    },*/
    {
      id: 3,
      text: "Especifica la ponderación y cualificación obtenida de cada asignatura",
    },
    { id: 4, 
      style: "text-sky-900 text-md md:text-xl font-bold",
      text: `Deja que elNotas te calcule que necessitas en el final !!`
    },
    { id: 5, 
      text: "Una vez calculado, guárdate la asignatura para usarla en un futuro" 
    }
  ];

  return (
    <div className="flex flex-col justify-center content-center mx-auto px-4w-full ">
      <h3 className="text-md mb-5 md:text-2xl font-courgette text-sky-900/90 font-bold text-center w-5/6 md:w-4/6 mx-auto">¿Quieres saber que necesitas para aprobar la asignatura?</h3>
      <ul className="list-decimal list-inside px-3 md:px-0 marker:text-sky-900/80 marker:font-courgette my-3">
        {instructions.map((instruction) => (
        <li className={`mb-1 text-gray-800 text-sm md:text-lg ${instruction?.style}`} key={`instr-${instruction.id}`}>
          {instruction.text}
        </li>
      ))}
      </ul>
    </div>
  );
}

export default Instructions;
