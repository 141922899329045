import React from "react";
import { useEffect, useState } from "react";

function CriteriaBtn(props) {
  const { e, evaluators, setEvaluators } = props;
  const [classNames, setClassNames] = useState();

  useEffect(() => {
    if (e.isSub && e.isActive) {
      setClassNames("font-sans h-full w-56 px-4 md:w-full md:px-8 bg-sky-700 text-white text-sm md:text-lg lg:text-2xl md:font-semibold border-0 rounded-l-lg cursor-pointer hover:bg-sky-800 transition-transform");
    } else if (!e.isSub && e.isActive) {
      setClassNames("font-sans h-full w-full px-4 md:w-full md:px-7 bg-sky-800 text-white text-md md:text-xl lg:text-3xl md:font-bold border-0 rounded-l-lg cursor-pointer hover:bg-sky-900 transition-transform");
    } else if (e.isSub && !e.isActive) {
      setClassNames("font-sans h-full w-full md:w-40 px-4 bg-sky-700 text-white text-sm md:text-lg lg:text-2xl md:font-semibold border-0 rounded-lg cursor-pointer hover:bg-sky-800 transition-transform");
    } else {
      setClassNames("font-sans h-full w-full md:w-72 px-4 bg-sky-800 text-white text-md md:text-xl lg:text-3xl md:font-bold border-0 rounded-lg cursor-pointer hover:bg-sky-900");
    }
  }, [e.isSub, e.isActive]);

  const handleClick = () => {
    let id = e.id;
    setEvaluators(
      evaluators.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            isActive: !e.isActive,
          };
        } else {
          return e;
        }
      })
    );
  };

  return (
    <button className={classNames} onClick={handleClick}>
      {e.name}
    </button>
  );
}

export default CriteriaBtn;
