import React, {useEffect} from "react";
import NumberPicker from "./numberPicker/NumberPicker";
import lodash from "lodash";
import { types, useAlert } from "react-alert";
import { checkMarks, checkTotalPercent, checkPercents } from '../../utils/checks'; 
import { BadgeCheckIcon, BookOpenIcon } from "@heroicons/react/solid";

function Calculator(props) {

  const {
        finalMark,
        setFinalMark,
        wantedMark,
        setWantedMark,
        evaluators,
        setEvaluators,
        subEvaluators,
        currentMark,
        setCurrentMark,
        isTotalGrade,
        setIsTotalGrade,
        setIsApproved,
        setNotPossibleApproval
  } = props;
  const alert = useAlert();
  let eMark = 0
  let id;
  
  useEffect(() => {
    setEvaluators(
      evaluators.map((e) => {
        if (e.isActive) {
          subEvaluators.forEach((sub) => {
            if (sub.isActive) {
              if(sub.parentId === e.id) {
                eMark += (sub.mark * sub.percent) / 100;
                id = sub?.parentId;
              } else {
                return;
              }
            }
          })          
        }  
        if (eMark !== 0 && eMark !== undefined && e.id === id) {
          return {
              ...e,
              mark: eMark.toFixed(2),
            };
        } else {
          return e;
        }
      })
    );
  }, [subEvaluators])
  
  useEffect(() => {
    if(checkPercents(evaluators) && checkMarks(evaluators) && checkTotalPercent(evaluators)) {
        let marks = lodash.sum(evaluators.map((e) => (e.percent / 100) * e.mark));
        setCurrentMark(marks.toFixed(2));
        
        let finalPercent = 1 - lodash.sum(evaluators.map((e) => e.percent / 100));
        setIsTotalGrade(finalPercent === 0 ? true : false);
        let mark = ((wantedMark - marks) / finalPercent).toFixed(2);
        setIsApproved(mark <= 0 ? true : false);
        setNotPossibleApproval(mark >= 10 ? true : false);

        if (isTotalGrade) {
            setFinalMark(marks.toFixed(2));
            setCurrentMark("Tu nota es 👆")
        } else {
            if (mark >= 10) {
                setFinalMark(<BookOpenIcon className="h-40 w-40 mx-auto" />);
                return;
            } else if (mark < 0) {
                setFinalMark(<BadgeCheckIcon className="h-40 w-40 mx-auto" />);
                return;
            } else {
                setFinalMark(mark);
                setCurrentMark(marks.toFixed(2));
                return;
            }
        }
    } else {
        setFinalMark("Epa");
        setCurrentMark("!!");
        return;
    }
}, [evaluators, wantedMark])

  useEffect(() => {
      if (finalMark === "Ojo"){
          alert.error(`Revisa que los números esten bien escritos`, {
            type: types.ERROR,
          });
      } 
      if (finalMark === "Epa") {
          alert.error(`Comprueba que las notas y los porcentajes sean correctos`, {
              type: types.ERROR,
          });
      }
  }, [finalMark])

  return (
    <div className="h-screen w-full flex justify-center content-center">
      <div className="h-full w-5/6 flex flex-col justify-center text-center">
          {currentMark !== "0.00" && <h1 className="w-full text-7xl p-3 font-extrabold text-white">{finalMark}</h1>}
          {currentMark !== "0.00" && <h5 className="m-0 text-3xl font-bold text-gray-200">{currentMark}</h5>}
      </div>
      <NumberPicker wantedMark={wantedMark} setWantedMark={setWantedMark} />
    </div>
  );
}

export default Calculator;