import React, {useRef} from 'react';
import { types, useAlert } from "react-alert";

const SaveForm = (props) => {
    const {
        evaluators,
        subEvaluators,
        setModalIsOpen,
        subjects,
        newSubject,
        setNewSubject
      } = props;
    const alert = useAlert();
    const inputName = useRef(null);

    const handleClick = () => {
        setNewSubject({
            ...newSubject,
            id: subjects.length + 1,
            name: inputName.current?.value,
            evaluators: evaluators,
            subEvaluators: subEvaluators,
        });
        inputName.current.value = "";
        alert.success("L'assignatura s'ha guardat correctament", {
            type: types.SUCCESS,
        });
        setModalIsOpen(false);
    };
    

    return (
        <div className="flex flex-col w-full h-full justify-content content-center">
            <label className="mb-3 text-md md:text-2xl font-courgette text-sky-900/90 font-bold text-center w-4/6 mx-auto">Escribe el nombre de la asignatura</label>
            <span className="flex content-center mb-5 text-xs md:text-sm text-gray-400 text-center w-5/6 mx-auto">No quedara guardada si el navegador esta en modo incógnito</span>
            <input 
                type="text"  
                placeholder="Nom de l'assignatura"
                ref={inputName} 
                className="block self-center m-4 w-5/6 md:w-4/6 lg:w-2/6 font-3xl text-center p-3 outline-none border-2 rounded-md bg-gray-200 text-gray-900 focus:border-2 focus:border-gray-500/60"  
                />
            <button
                onClick={handleClick}
                className="self-center px-3 py-2 border border-transparent rounded-lg text-l text-gray-100 bg-green-700 my-2 md:my-4 cursor-pointer"
            >Guardar</button>
        </div>
    );
};

export default SaveForm;
