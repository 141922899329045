import React, {useRef} from "react";
import { PlusSmIcon } from "@heroicons/react/outline";

function SubAdder(props) {
  const { subs, setSubs, newSub, setNewSub, e } = props;
  const inputSub = useRef()
  
  const handleClick = () => {
    let newId = subs.length;
    if(inputSub.current.value !== "") {
      newId = subs.length + 1;
      setNewSub({
        ...newSub,
        id: newId,
      });
    } 
    
    if (
      newSub.name !== "" &&
      inputSub.current.value !== ""
    ) {
      setSubs([...subs, newSub]);
    }
    inputSub.current.value = "";
  };
  const handleChange = (event) => {
    setNewSub({
      ...newSub,
      parentId: e.id,
      name: event.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="flex content-center h-7 md:h-12 min-h-content mt-2 md:mt-4 mb-2 px-4">
      <input
        id="subInput"
        ref={inputSub}
        className="h-full bg-transparent text-sky-700 text-xs md:text-lg lg:text-xl border-0 bg-gray-200 rounded-l-md rounded-r-none px-3 cursor-pointer outline-none" 
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder="Añade un sub-criterio"
      />
      <button className="w-6 md:w-10 h-full bg-sky-700 border-0 rounded-r-md cursor-pointer text-gray-100" 
      onClick={handleClick}
      >
        <PlusSmIcon className="w-4 h-4 md:h-6 md:w-6 m-auto" />
      </button>
    </div>
  );
}

export default SubAdder;
