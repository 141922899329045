import React from "react";
import {XIcon} from "@heroicons/react/outline";

const Modal = (props) => {
  const { setIsOpen, modalContent } = props;

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="visible flex justify-center content-center fixed left-0 top-0 w-full h-full overflow-auto bg-gray-900/30 z-10">
      <div className="flex flex-col bg-white content-center m-auto p-5 border border-gray-300 rounded-lg w-5/6 lg:w-4/6 z-20">
        <button className="flex justify-end font-bold border-0 bg-transparent text-gray-800 cursor-pointer float-right w-full text-end" onClick={hideModal}>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;
