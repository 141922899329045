import React from 'react';

const NumberPickerTop = (props) => {
    const { wantedMark, setWantedMark } = props;
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const handleClick = (e) => {
        if(e.target.value === undefined) return;
        setWantedMark(parseInt(e.target.value));
    };

    return (
        <div className="relative w-full flex content-center justify-center mb-2 bg-gray-600/20" onClick={handleClick}>
        {nums.map((num) => {
            if (num === wantedMark) {
                return (
                    <button
                    value={num}
                    key={num}
                    className="w-5 h-9 m-auto border-none cursor-pointer text-gray-100 font-semibold text-md md:text-3xl p-1 mx-0 sm:mx-2 transition-all"
                    onClick={handleClick}
                    >
                        {num}
                    </button>
                );
            } else {
                return (
                    <button
                    value={num}
                    key={num}
                    className="w-5 h-10 bg-transparent border-none cursor-pointer text-gray-200 text-sm md:text-2xl mx-0 sm:mx-1 p-1 sm:p-2 transition-all"
                    onClick={handleClick}
                    >
                        {num}
                    </button>
                );
            }
        })}
        </div>
    );
};

export default NumberPickerTop;
