import React from "react";
import InfoBtn from "./infoBtn/InfoBtn";
import ResetBtn from "./resetBtn/ResetBtn";
import SaveBtn from "./saveBtn/SaveBtn";
import SubjectsBtn from "./subjectsBtn/SubjectsBtn";

function Topbar(props) {
  const {
      subjects,
      setSubjects,
      setModalIsOpen,
      setModalContent,
      evaluators,
      setEvaluators,
      subEvaluators,
      setSubEvaluators,
      setFinalMark,
      setWantedMark,
      newSubject,
      setNewSubject
  } = props;

  return (
      <ul className="hidden lg:flex list-none content-end mb-0">
        <li className="flex justify-center">
          <InfoBtn
            setModalIsOpen={setModalIsOpen}
            setModalContent={setModalContent}
          />
        </li>
        <li className="flex justify-center">
          <ResetBtn
              setEvaluators={setEvaluators}
              setSubevaluators={setSubEvaluators}
              setFinalMark={setFinalMark}
              setWantedMark={setWantedMark}
          />
        </li>
        <li className="flex justify-center">
          <SubjectsBtn
            subjects={subjects}
            setSubjects={setSubjects}
            setModalIsOpen={setModalIsOpen}
            setModalContent={setModalContent}
            setEvaluators={setEvaluators}
            setSubEvaluators={setSubEvaluators}
          />
        </li>
        <li className="flex justify-center">
          <SaveBtn
            evaluators={evaluators}
            subEvaluators={subEvaluators}
            setModalIsOpen={setModalIsOpen}
            setModalContent={setModalContent}
            subjects={subjects}
            setSubjects={setSubjects}
            newSubject={newSubject}
            setNewSubject={setNewSubject}
          />
        </li>
      </ul>
  );
}

export default Topbar;
