import React from "react";
import { /*ArrowCircleDownIcon, ArrowCircleUpIcon,*/ TrashIcon } from "@heroicons/react/solid"
import { useEffect, useState } from "react";

function EvaluatorOptions(props) {
  const { e, evaluators, setEvaluators } = props;
  const [classNames, setClassNames] = useState([]);

  useEffect(() => {
    if (e.isSub) {
      setClassNames([
        "flex flex-col justify-center content-center w-7 transition-all",
        "w-7 md:w-10 h-full text-white text-xs md:text-md bg-red-600/90 border-0 rounded-r-lg cursor-pointer hover:bg-red-600 transition-all",
        "",
      ]);
    } else {
      setClassNames([
        "flex flex-col justify-center content-center w-8 transition-all ",
        "w-8 md:w-9 h-full text-white text-xs md:text-lg bg-red-600/90 border-0 rounded-tr-lg rounded-br-lg cursor-pointer hover:bg-red-600 transition-all",
        "w-8 md:w-9 h-3/6 text-white text-xs md:text-lg bg-gray-500/90 border-0 rounded-br-lg cursor-pointer hover:bg-gray-500 transition-all",
      ]);
    }
  }, [e.id, e.mark, e.isSub, evaluators]);

  /*const handleShowAdder = () => {
    let id = e.id;
    setEvaluators(
      evaluators.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            addSub: !e.addSub,
          };
        } else {
          return {
            ...e,
            addSub: false,
          };
        }
      })
    );
  };*/

  const handleRemove = () => {
    let filteredEvaluators = evaluators.filter((item) => item !== e);
    setEvaluators(filteredEvaluators);
  };

  return (
    <div className={classNames[0]}>
      <button className={classNames[1]} onClick={handleRemove}>
        <TrashIcon className="w-3 h-3 md:w-5 md:h-5 m-auto" />
      </button>
      {/*!e.isSub && (
        <button className={classNames[2]} onClick={handleShowAdder}>
          {e.addSub && <ArrowCircleUpIcon className="w-3 h-3 md:w-5 md:h-5 m-auto" />}
          {!e.addSub && <ArrowCircleDownIcon className="w-3 h-3 md:w-5 md:h-5 m-auto" />}
        </button>
      )*/}
    </div>
  );
}

export default EvaluatorOptions;
