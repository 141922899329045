import React, { useState, useEffect }  from 'react';
import DropDownMenu from './dropDownMenu/DropDownMenu';
import Topbar from './Topbar';

const ResponsiveTopbar = (props) => {
    const {
        setModalIsOpen,
        setModalContent,
        evaluators,
        setEvaluators,
        subEvaluators,
        setSubEvaluators,
        setFinalMark,
        setFinalMarkTop,
        setWantedMark
    } = props;

    const [subjects, setSubjects] = useState(() => {
        try {
            const item = localStorage.getItem("subjects");
            return item ? JSON.parse(item) : [];
        } catch (error) {
            console.error(error);
            return [];
        }
    });
    const [newSubject, setNewSubject] = useState({
        name: undefined
    });

    useEffect(() => {
        if (subjects !== [{}]) {
            try {
                localStorage.setItem("subjects", JSON.stringify(subjects));
            } catch (e) {
                console.error(e);
            }
        }
      }, [subjects]);

    useEffect(() => {
        if (newSubject.name !== undefined) {
            setSubjects([
                ...subjects,
                newSubject
            ]);
        }
    }, [newSubject])

    return (
        <div className="container flex justify-between items-center mx-auto w-100">
            <h1 className="font-courgette text-4xl md:text-5xl lg:text-6xl font-extrabold text-sky-900 m-0 content-start">ElNotas</h1>
            <DropDownMenu 
                subjects={subjects}
                setSubjects={setSubjects}
                setModalIsOpen={setModalIsOpen}
                setModalContent={setModalContent}
                evaluators={evaluators}
                setEvaluators={setEvaluators}
                subEvaluators={subEvaluators}
                setSubEvaluators={setSubEvaluators}
                setFinalMark={setFinalMarkTop}
                setWantedMark={setWantedMark}
                newSubject={newSubject}
                setNewSubject={setNewSubject}
            />
            <Topbar 
                subjects={subjects}
                setSubjects={setSubjects}
                setModalIsOpen={setModalIsOpen}
                setModalContent={setModalContent}
                evaluators={evaluators}
                setEvaluators={setEvaluators}
                subEvaluators={subEvaluators}
                setSubEvaluators={setSubEvaluators}
                setFinalMark={setFinalMark}
                setWantedMark={setWantedMark}
                newSubject={newSubject}
                setNewSubject={setNewSubject}
            />    
        </div>
    )
};

export default ResponsiveTopbar;
