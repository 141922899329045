import React from "react";
import CriteriaBtn from "./crtieriaBtn/CrtieriaBtn";
import PercentageInput from "./percentageInput/PercentageInput";
import MarkInput from "./markInput/MarkInput";
import EvaluatorOptions from "./evaluatorOptions/EvaluatorOptions";
import { useEffect, useState } from "react";

function Evaluator(props) {
  const { e, evaluators, setEvaluators, /*finalMark, setFinalMark*/ } = props;
  const [classNames, setClassNames] = useState();

  useEffect(() => {
    if (e.isSub) {
      setClassNames("flex justify-between h-10 md:h-16 min-h-content w-full px-4 lg:w-4/6 mt-3 mb-1 md:mt-4");
    } else {
      setClassNames("flex justify-between h-12 md:h-20 min-h-content w-full px-2 mt-4 md:mt-5");
    }
  }, [e.isSub]);

  return (
    <div className={classNames}>
      <CriteriaBtn
        e={e}
        evaluators={evaluators}
        setEvaluators={setEvaluators}
      />
      {e.isActive && (
        <PercentageInput
          e={e}
          evaluators={evaluators}
          setEvaluators={setEvaluators}
        />
      )}
      {e.isActive && (
        <MarkInput
          e={e}
          evaluators={evaluators}
          setEvaluators={setEvaluators}
        />
      )}
      {e.isActive && (
        <EvaluatorOptions
          e={e}
          evaluators={evaluators}
          setEvaluators={setEvaluators}
        />
        
      )}
    </div>
  );
}

export default Evaluator;
