import React from "react";
import styles from "./App.module.css";
import { useEffect, useState } from "react";
import Calculator from "../../components/calculator/Calculator";
import EvaluatorAdder from "../../components/evaluator/evalutaorAdder/EvaluatorAdder";
import EvaluatorGroup from "../../components/evaluatorGroup/EvaluatorGroup";
import {
  transitions,
  positions,
  Provider as AlertProvider,
} from "react-alert";
import Modal from "../../components/modal/Modal";
import CalculatorTop from "../../components/calculator/CalculatorTop";
import TopbarResponsive from "../../components/topbar/TopbarResponsive";
// import { EmojiHappyIcon, EmojiSadIcon } from "@heroicons/react/outline";

function App() {
  const [evaluators, setEvaluators] = useState([
    {
      id: 1,
      name: "Parcial 1",
      percent: 0,
      mark: 0,
      isActive: false,
      isSub: false,
      addSub: false,
    },
    {
      id: 2,
      name: "Parcial 2",
      percent: 0,
      mark: 0,
      isActive: false,
      isSub: false,
      addSub: false,
    },
    {
      id: 3,
      name: "Parcial 3",
      percent: 0,
      mark: 0,
      isActive: false,
      isSub: false,
      addSub: false,
    },
    {
      id: 4,
      name: "Laboratorio",
      percent: 0,
      mark: 0,
      isActive: false,
      isSub: false,
      addSub: false,
    },
    {
      id: 5,
      name: "Proyecto",
      percent: 0,
      mark: 0,
      isActive: false,
      isSub: false,
      addSub: false,
    },
  ]);
  const [color, setColor] = useState(styles.grey);
  const [colorTop, setColorTop] = useState(styles.grey);
  const [isMobile, setIsMobile] = useState(window.innerWidth > 1024 ? false : true);
  const [finalMarkTop, setFinalMarkTop] = useState();
  const [finalMark, setFinalMark] = useState();
  const [wantedMark, setWantedMark] = useState(5);
  const [currentMark, setCurrentMark] = useState(0);
  const [newEvaluator, setNewEvaluator] = useState({
    name: "",
    percent: 0,
    mark: 0,
    isActive: false,
    isSub: false,
    addSub: false,
  });
  const [newSub, setNewSub] = useState({
    name: "",
    percent: 0,
    mark: 0,
    isActive: false,
    isSub: true,
  });
  const [subEvaluators, setSubEvaluators] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [isTotalGrade, setIsTotalGrade] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [notPossibleApproval, setNotPossibleApproval] = useState(false);
  
  const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '50px',
    transition: transitions.FADE,
  };

  const AlertTemplate = ({ options, message }) => {
      let type = options.type;
      let color;
      if (type === 'success') {
        color = "bg-green-700/80"
      } else if (type === 'error') {
        color = "bg-red-700/80"
      } else {
        color = "bg-sky-900/60"
      }
      return (
        <div className={`mt-3 mb-5 mx-4 px-5 font-sans text-sm md:text-md py-2 flex content-center justify-center text-center rounded-md text-white ${color}`}>
          {message}
          {/* {type === 'error' && <EmojiSadIcon className="ml-3 my-auto h-4 w-4" />}
          {type === 'success' && <EmojiHappyIcon className="ml-3 my-auto h-4 w-4" />} */}
        </div>
      );
  };

  useEffect(() => {
    const checkWidth = () => {
        if(window.innerWidth > 1024) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }
    window.addEventListener("resize", checkWidth)
  }, [])

  useEffect(() => {
    if (finalMark !== undefined && currentMark !== "0.00") {
      if (finalMark < 3 || isApproved) {
        setColor(styles.green);
      } else if (3 <= finalMark && finalMark <= 4) {
        setColor(styles.orange);
      } else if (finalMark > 4 || notPossibleApproval || finalMark === "Ojo" || finalMark === "Epa") {
        setColor(styles.red);
      } else {
        setColor(styles.grey);
      }
    } else {
      setColor(styles.grey);
    }
  }, [finalMark, currentMark]);

  useEffect(() => {
    if (finalMarkTop !== undefined && currentMark !== "0.00") {
      if (finalMarkTop < 3 || isApproved) {
        setColorTop(styles.green);
      } else if (3 <= finalMarkTop && finalMarkTop <= 4) {
        setColorTop(styles.orange);
      } else if (finalMarkTop > 4 || notPossibleApproval || finalMarkTop === "Ojo" || finalMarkTop === "Epa") {
        setColorTop(styles.red);
      } else {
        setColorTop(styles.grey);
      }
    } else {
      setColorTop(styles.grey);
    }
  }, [finalMark, currentMark]);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <main className="font-sans bg-white h-full w-full">
        <div className="flex w-full">
          {modalIsOpen && (
            <Modal setIsOpen={setModalIsOpen} modalContent={modalContent} />
          )}
          <div className="p-4 sm:p-6 md:p-8 lg:p-12 w-screen h-full lg:w-5/6">
            <TopbarResponsive 
              setModalIsOpen={setModalIsOpen}
              setModalContent={setModalContent}
              evaluators={evaluators}
              setEvaluators={setEvaluators}
              subEvaluators={subEvaluators}
              setSubEvaluators={setSubEvaluators}
              setFinalMark={setFinalMark}
              setWantedMark={setWantedMark}
              setFinalMarkTop={setFinalMarkTop}
            />
            <div className="bg-gray-300/90 mb-4 border md:h-1 rounded-md mt-4 mb-2" />
            <div className={`flex lg:hidden align-center justify-center l-0 h-48 rounded-xl ${colorTop}`}>
              {isMobile && <CalculatorTop
                finalMarkTop={finalMarkTop}
                setFinalMarkTop={setFinalMarkTop}
                wantedMark={wantedMark}
                setWantedMark={setWantedMark}
                evaluators={evaluators}
                setEvaluators={setEvaluators}
                subEvaluators={subEvaluators}
                currentMark={currentMark}
                setCurrentMark={setCurrentMark}
                isTotalGrade={isTotalGrade}
                setIsTotalGrade={setIsTotalGrade}
                setIsApproved={setIsApproved}
                setNotPossibleApproval={setNotPossibleApproval}
              />}
            </div>
            <div className={`${styles.evaluators} flex flex-col w-full py-2 lg:h-5/6 overflow-y-scroll`}>
              {evaluators.map((e) => (
                <EvaluatorGroup
                  key={`Evaluator group:${e.id}`}
                  e={e}
                  evaluators={evaluators}
                  setEvaluators={setEvaluators}
                  subs={subEvaluators}
                  setSubs={setSubEvaluators}
                  newSub={newSub}
                  setNewSub={setNewSub}
                  finalMark={finalMark}
                  setFinalMark={setFinalMark}
                />
              ))}
              <EvaluatorAdder
                evaluators={evaluators}
                setEvaluators={setEvaluators}
                newEvaluator={newEvaluator}
                setNewEvaluator={setNewEvaluator}
              />
            </div>
          </div>
          <div className={`hidden lg:flex align-center justify-center w-2/6 ${color}`}>
            {!isMobile && <Calculator
              finalMark={finalMark}
              setFinalMark={setFinalMark}
              wantedMark={wantedMark}
              setWantedMark={setWantedMark}
              evaluators={evaluators}
              setEvaluators={setEvaluators}
              subEvaluators={subEvaluators}
              currentMark={currentMark}
              setCurrentMark={setCurrentMark}
              isTotalGrade={isTotalGrade}
              setIsTotalGrade={setIsTotalGrade}
              setIsApproved={setIsApproved}
              setNotPossibleApproval={setNotPossibleApproval}
            />}
          </div>
        </div>
      </main>
    </AlertProvider>
  );
}

export default App;
